import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { GameContext } from '../../Context';
import './cypher.css';
import GameTimer from '../GameTimer';

const random = [0, 1, 0, 0, 1, 0, 1, 1, 1, 0, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1, 0]

const Cypher = (props) => {
    const gameContext = useContext(GameContext);

    const [inputText, setInputText] = useState('');
    const [target, setTarget] = useState(props.targetText);
    const [guessText, setGuessText] = useState('');
    const [partiallyDecoded, setPartiallyDecoded] = useState('');
    const [cipheredText, setCipheredText] = useState('');
    const [shift, setShift] = useState(1);
    const [completed, setCompleted] = useState(false);

    useEffect(() => {
        setCipheredText(caesarCipher(target, shift));
        // partially decode the text
        // remove random letters from the ciphered text and replace with spaces
        let partiallyDecoded = '';
        for (let i = 0; i < target.length; i++) {
            if (random[i] === 1) {
                partiallyDecoded += '-';
            } else {
                partiallyDecoded += target[i];
            }
        }

        setPartiallyDecoded(partiallyDecoded);
    }, [props.inputText, shift, target]);

    useEffect(() => {

    }, [inputText]);

    const caesarCipher = (str, shift) => {
        return str.replace(/[a-z]/gi, (char) => {
            const start = char <= 'Z' ? 65 : 97;
            return String.fromCharCode(((char.charCodeAt(0) - start + shift) % 26) + start);
        });
    };

    function handleComplete() {
        setCompleted(true);
        setTimeout(() => {
            props.onComplete();
        }, 1000);
    }

    const handleInputChange = (event) => {
        if (!event.target.value) {
            return;
        }
        const value = event.target.value.toLowerCase();
        setGuessText(value);
        if (value === props.targetText.toLowerCase()) {
            handleComplete();
        }
    };

    /** color code the cipher character if the letter on input text is correct */
    function getColoredText() {
        return inputText.split('').map((char, i) => {
            if (i < inputText.length && char === target[i]) {
                return <span className='correct'>{char}</span>;
            } else {
                return <span className='incorrect'>{char}</span>;
            }
        });
    }


    return (
        <div className="cipher">
            <input
                className='inputcipher'
                type="text"
                value={inputText}
                placeholder={partiallyDecoded}
                onChange={(e) => {
                    setInputText(e.target.value);
                    handleInputChange(e);
                }}
            />
            {completed && <span>✅</span>}

            <div className='cipherscreen'>
                <p className='fixed'>{cipheredText}</p>
                <p className='fixed'>{partiallyDecoded}</p>
                <p className='fixed'>{getColoredText()}</p>
            </div>
        </div>
    );

}

export default Cypher;