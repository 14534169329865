import React, { useEffect } from 'react';
import GameHint from './GameClue';

let timerid = 0;

const Quiz = (props) => {

    const [value, setValue] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [placeholder, setPlaceholder] = React.useState(props.placeholder);

    useEffect(() => {
        console.log("Input");
        // convert all answers to lowercase
        
        if (props.showAnswer) {
            setValue(props.answers[0]);
        }
        if (timerid) {
            clearTimeout(timerid);
        }
        setTimeout(() => {
            let ph = props.answers[0][0];
            while (ph.length < props.answers[0].length) {
                ph += "_";
            }
            setPlaceholder(ph);
        }, 30000);
    }, []);

    function doit(e) {
        setValue(e.target.value);
        console.log(value, props.answers);        
    }

    function skip() {
        setValue("");
        props.onSkip();
    }

    function check() {
        const lvalue = value.toLowerCase().trim();

        setMessage("");
        if (!props.answers) {
            setMessage("No answers provided. Please reload the page.");
        }
        // convert props.answers to lower case
        const lanswers = props.answers.map((a) => a.toLowerCase().trim());

        if (lanswers.includes(lvalue)) {
            console.log("correct");            
            setMessage("");
            setValue("");
            props.onAnswered();
        } else {
            console.log("incorrect");
            setMessage("Try again");
        }
    }

    return (
        <div className="vcontainer quiz">
            {props.clue && <div className='clue'>{props.clue}</div>}
            <input onChange={doit} type="text" placeholder={placeholder} value={value}></input>
            {props.text}
            <div className='button' onClick={check}>Submit Answer</div>
            {props.hint && <GameHint hint={props.hint} penalty={props.penalty}></GameHint>}
            <div className='button' onClick={skip}>Skip</div>
            {message}
        </div>
    );
}

export default Quiz;