import React, { useEffect, useState } from 'react';
import MiniButton from '../minibutton/MiniButton';
import './audioplayer.css';
import { clear } from '@testing-library/user-event/dist/clear';

let seekTimer = 0;
let dragging = false;
let saveTimer = 0;

const AudioPlayer = (props) => {

    const [chapter, setChapter] = useState(loadInitial("chapter", 1));
    const [part, setPart] = useState(loadInitial("part", 1));
    const [position, setPosition] = useState(loadInitial("position", 0));
    const [src, setSrc] = useState("");
    const [hasClickedPlay, setHasClickedPlay] = useState(false);
    const [paused, setPaused] = useState(true);

    const [length, setLength] = useState(0);

    useEffect(() => {

        loadPosition();

        const audio = getAudio();
        audio.onloadeddata = () => {
            const seekbar = document.getElementById("seekbar");
            if (!seekbar) {
                return;
            }
            seekbar.max = audio.duration;
            setLength(length => audio.duration);
        };
        audio.onended = () => {
            gotoNextPart();
        };
        // seek position
        audio.ontimeupdate = () => {
            if (!dragging) {
                const seekbar = document.getElementById("seekbar");
                if (!seekbar) {
                    return;
                }
                seekbar.value = audio.currentTime;
                if (!audio.paused) {
                    setPosition(position => audio.currentTime);
                }
            }
        };

        return () => {
            getAudio()?.pause();
            clearTimeout(seekTimer);
            clearTimeout(saveTimer);
        };

    }, []);

    useEffect(() => {
        if (paused) {
        getAudio()?.pause();
        } else {
            if (hasClickedPlay) {
                getAudio()?.play();
            }
        }
    }, [paused]);

    useEffect(() => {
        savePosition();
    }, [position]);

    /**
     * play the audio if the play prop is true
     */
    useEffect(() => {
        console.log("audio play cmd ", props.play, props.updateCount)
        const audio = document.getElementById("audioplayer");
        try {
            if (hasClickedPlay) {
                props.play == true? audio.play() : audio.pause();
            }
        } catch (e) {
            console.log(e);
        }

    }, [props.play, props.updateCount]);


    useEffect(() => {
        //const audio = document.getElementById("audioplayer");
        //loadPosition();
    }, [src]);

    useEffect(() => {
        const audio = document.getElementById("audioplayer");
        audio.currentTime = 0;
        const file = "/" + props.folder + "/c" + chapter + "p" + part + ".mp3";
        console.log("AudioPlayer: ", props, file);
        setSrc(file);
        audio.src = file;
        savePosition();
        //audio.play();
    }, [chapter, part]);

    useEffect(() => {
        const audio = document.getElementById("audioplayer");
        if (audio.paused && hasClickedPlay) {
            audio.currentTime = Math.max(position - 2, 0);
            audio.play();
        } else {
            audio.pause();
        }
    }, [hasClickedPlay]);

    /**
     * save audio chapter, part, and position to localstorage
     * @returns 
     */
    function savePosition() {
        const audio = document.getElementById("audioplayer");
        const key = "audioplayer_" + props.folder;
        const value = {
            chapter: chapter,
            part: part,
            position: position || 0
        };
        console.log("savePosition", value);
        localStorage.setItem(key, JSON.stringify(value));
    }

    function loadInitial(key, defaultValue) {
        const item = "audioplayer_" + props.folder;
        const value = localStorage.getItem(item);
        if (value) {
            const data = JSON.parse(value);
            return data[key];
        }
        return defaultValue;
    }

    /**
     * load audio chapter, part, and position from localstorage
     * @returns
     *  
     * */
    function loadPosition() {
        const key = "audioplayer_" + props.folder;
        const value = localStorage.getItem(key);
        if (value) {
            const data = JSON.parse(value);
            setChapter(chapter => data.chapter);
            setPart(part => data.part);
            setPosition(position => data.position);
            const audio = document.getElementById("audioplayer");
            audio.currentTime = data.position;
        }


        console.log("loadPosition", value);
    }

    function gotoPreviousPart(immediate) {
        setTimeout(() => {
            setPosition(0);
            setPart((part) => part - 1);
        }, immediate ? 0 : 1000);
    }

    function gotoNextPart(immediate) {
        setTimeout(() => {
            setPosition(0);
            setPart((part) => part + 1);
        }, immediate ? 0 : 1000);
    }

    function onSeek(value) {
        dragging = true;
        if (seekTimer) {
            clearTimeout(seekTimer);
        }
        seekTimer = setTimeout(() => {
            dragging = false;
            const audio = document.getElementById("audioplayer");
            audio.currentTime = value;
            setPosition(value);
        }, 500);
    }

    function pause() {
        setHasClickedPlay(true);
        setPaused(paused => !paused);
    }

    function getCurrentTime() {
        return getAudio()?.currentTime;
    }

    function getAudio() {
        return document.getElementById("audioplayer");
    }

    return (
        <div className="vcontainer ">
            <div className="hcontainer audioplayer">
                <MiniButton className="nextbutton" onClick={() => gotoPreviousPart(true)}>⏮</MiniButton>
                <MiniButton className="nextbutton" onClick={() => pause(true)}>{paused && <span>▶</span>}{!paused && <span>⏸</span>}</MiniButton>
                <audio controls autoPlay id="audioplayer">
                    <source type="audio/mp3" />
                    Your browser does not support the audio element.
                </audio>
                <div className='partlabel'>Ch {chapter}.{part}</div>
                <MiniButton className="nextbutton" onClick={() => gotoNextPart(true)}>⏭</MiniButton>
            </div>

            <input
                id="seekbar"
                type="range"
                min="0"
                max={props.duration}
                onChange={(e) => onSeek(e.target.value)}
            />
            {getCurrentTime()}
        </div>
    );
}

export default AudioPlayer;