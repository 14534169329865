import React, { useContext } from 'react';
import Players from '../components/players/Players';
import GameButton from '../components/GameButton';
import { GameContext } from '../Context';
import { getUser, hasTicketsForGame } from '../lib/user';
import NavButton from '../components/NavButton';

/**
 * Start Game Page
 * @param {*} props 
 * @returns 
 */
const Start = (props) => {

    const gameContext = useContext(GameContext);

    function startGame() {
        localStorage.setItem('gameName', gameContext.gameName);
        gameContext.startGPS();
        props.setPage(gameContext.gameName);
    }

    function isLoggedIn() {
        const user = getUser();
        if (user?.loggedin) {
            return true;
        }
        return false;
    }

    return (
        <div className='vcontainer'>
            <h1>Start</h1>
            <br></br>
            <h2>Team Members</h2>
            <Players></Players>
            <br></br>
            <NavButton setPage={() => props.setPage("test", "start")}>Test your GPS</NavButton>            
            <br></br>
            { hasTicketsForGame(gameContext.gameName) && <GameButton to={startGame}>Play {gameContext.gameName.toUpperCase()}</GameButton> }
            { !hasTicketsForGame(gameContext.gameName) && <GameButton to={()=>props.setPage("tickets")}>Buy Tickets for {gameContext.gameName.toUpperCase()}</GameButton> }            
            { !isLoggedIn() && <NavButton setPage={() => props.setPage("login")}>Login to Play</NavButton> }
        </div>
    );
};

export default Start;