import L from 'leaflet';

const greenIcon = L.icon({
    iconUrl: '/map/default.png',
    shadowUrl: '/map/default_shadow.png',

    iconSize: [35, 39], // size of the icon
    shadowSize: [50, 30], // size of the shadow
    iconAnchor: [22, 34], // point of the icon which will correspond to marker's location
    shadowAnchor: [20, 22],  // the same for the shadow
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});


const playerIcon = L.icon({
    iconUrl: '/map/employment.png',
    shadowUrl: '/map/default_shadow.png',

    iconSize: [32, 39], // size of the icon
    shadowSize: [50, 30], // size of the shadow
    iconAnchor: [22, 34], // point of the icon which will correspond to marker's location
    shadowAnchor: [20, 22],  // the same for the shadow
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});

const homeIcon = L.icon({
    iconUrl: '/map/residential-places.png',
    shadowUrl: '/map/default_shadow.png',

    iconSize: [32, 39], // size of the icon
    shadowSize: [50, 30], // size of the shadow
    iconAnchor: [22, 34], // point of the icon which will correspond to marker's location
    shadowAnchor: [20, 22],  // the same for the shadow
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});

const targetIcon = L.icon({
    iconUrl: '/map/games.png',
    shadowUrl: '/map/default_shadow.png',

    iconSize: [32, 39], // size of the icon
    shadowSize: [50, 30], // size of the shadow
    iconAnchor: [22, 34], // point of the icon which will correspond to marker's location
    shadowAnchor: [20, 22],  // the same for the shadow
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});

export { greenIcon, playerIcon, homeIcon, targetIcon };