import { useState, useEffect, useContext } from 'react';
import AudioPlayer from '../audioplayer/audioplayer';
import Location from '../Location/Location';
import { GameContext } from '../../Context';
import GameButton from '../GameButton';

let wakelock = null;
let checkTimer = 0;
let updateCount = 0;

const BookWalk = ({ book }) => {
    const [message, setMessage] = useState("");
    const gameContext = useContext(GameContext);
    //const [distance, setDistance] = useState(gameContext.distance);
    const [canPlay, setCanPlay] = useState(true);

    useEffect(() => {
        console.log("BookWalk");
        try {
            setMessage("wakeLock...");
            navigator.wakeLock.request('screen')
                .then((lock) => {
                    console.log('Wake Lock active');
                    setMessage("Wake Lock active");
                    wakelock = lock;
                    setMessage("");
                }
                );
        } catch (e) {
            console.log(e);
            setMessage("Wake Lock not supported");
        }

        if ('wakeLock' in navigator) {
            console.log('Wake Lock API supported');
            setMessage("Wake Lock API supported");
            
        } else {
            console.log('Wake Lock API not supported');
            setMessage("Wake Lock API not supported");
        }

        checkDistance();


        return () => {
            if (typeof wakelock === 'undefined') {
                return;
            }
            if (wakelock) {
                wakelock.release();
            }
        }
    }, []);

    useEffect(() => {
        console.log("BookWalk: ", gameContext);
    }, [gameContext.distance, gameContext.speed]);

    // periodically check if the player has moved
    function checkDistance() {
        if ( checkTimer ) {
            clearInterval(checkTimer);
        }

        checkTimer = setInterval(() => {
            updateCount++;
            if (gameContext.speed > 0) {
                setMessage(`You have moved ${gameContext.distance}m`);                
                setCanPlay(true);
            } else {
                setMessage("You have not moved");
                setCanPlay(false);
            }
            
        }, 1000);
    }

    //function canPlay() {
      //  return gameContext.distance > 1;
    //}


    return (
        <div>
            <h1>Book Walk</h1>
            <p>{book}</p>
            <p>{gameContext.distance}m {gameContext.speed}m/s</p>
            {message && <p>{message}</p>}
            {wakelock && <p>Wake Lock enabled</p>}
            <AudioPlayer play={canPlay} updateCount={updateCount} folder="maple"></AudioPlayer>
            <Location latitude={gameContext.latitude} longitude={gameContext.longitude}></Location>
            <GameButton to={() => gameContext.setStartToCurrent()}>Reset</GameButton>

        </div>
    );

}

export default BookWalk;