import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import NavButton from '../components/NavButton';
import { GameContext } from '../Context';

const Reset = (props) => {
    const [message, setMessage] = React.useState('');
    const gameContext = useContext(GameContext);

    function getLocalStorage(tag) {
        return localStorage.getItem(gameContext.gameName + "_" + tag);
    }

    function setLocalStorage(tag, value) {
        localStorage.setItem(gameContext.gameName + "_" + tag, value);
    }

    function reset() {
        // delete 'game' from local storage
        localStorage.removeItem(gameContext.gameName + "_" + 'game');
        localStorage.removeItem(gameContext.gameName + "_" + 'chapter');
        localStorage.removeItem(gameContext.gameName + "_" + 'stime');
        localStorage.removeItem(gameContext.gameName + "_" + 'etime');
        setMessage('Your game is reset.');
    }

    return (
        <div className='vcontainer'>
            <NavButton setPage={reset}>Reset Current Game</NavButton>
            {message}
        </div>
    );
}

export default Reset;