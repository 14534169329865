import React, { useEffect, useMemo, useRef, useState } from 'react';
import './jigsaw.css';
import { newPuzzle, setOnCompleteCallback } from './jigsawcode';

let timerId = 0;

const JigSaw = (props) => {
    const [time, setTime] = useState(90);
    const [complete, setComplete] = useState(false);

    useEffect(() => {
        console.log("useEffect");
        if (!props.onComplete) {
            console.error("ERROR: onComplete not set");
        }
        if (!props.initialImage) {
            console.error("ERROR: initialImage not set");
        }
        const lpuzzle = newPuzzle(props.initialImage);
        setOnCompleteCallback(onComplete);

    }, []);

    useEffect(() => {
        if (complete) {
            clearTimeout(timerId);
            return;
        }
        timerId = setTimeout(() => {
            setTime(time - 1);
        }, 1000);    
    }, [time]);

    function onComplete() {
        console.log("complete");
        setComplete(true);
        setTimeout(() => {
            props.onComplete();
        }, 3000);
    }

    function getTimer() {        
        return <div>{time} seconds</div>;
    }

    return (
        <div className="jigsawpuzzle">
            <div className={ time < 20 ? "timerNear" : "timer"}>{getTimer()}</div>
            <div> { complete && <div>You've Dont it!</div>}</div>
            <div id="forPuzzle"></div>         
            
        </div>
    );
}

export default JigSaw;