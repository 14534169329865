import React, { createContext, useEffect, useMemo, useState } from 'react';
import OnlineStatus from './components/OnlineStatus';
import { calculateDistance, calculateSpeed } from './components/Location/LocationFunctions';
import games from './games/games.json';

// Create a Context
const GameContext = createContext();
let watchid = 0;
//-33.913, 18.388
let testPositions = [{ lat: 0, lon: 0 }, { lat: 0, lon: 0 }, { lat: 0, lon: 0 }, { lat: 0, lon: 0 }];
function getRandomPosition(baseLat, baseLon, accuracy) {
    const randomOffset = () => (Math.random() - 0.5) * accuracy * 0.0001;
    return {
        lat: baseLat + randomOffset(),
        lon: baseLon + randomOffset()
    };
}
testPositions = Array.from({ length: 24 }, () => getRandomPosition(-33.9041358, 18.4114505, 5));

let distance = 0;
let speed = 0;
let startLatitude = 0;
let startLongitude = 0;
let previousLatitude = 0;
let previousLongitude = 0;
let latitude = 0;
let longitude = 0;
let targetLatitude = 0;
let targetLongitude = 0;
let previousTime = Date.now();
let currentTime = Date.now();
let updateTimer = 0;

// Create a provider component
const GameContextProvider = ({ children }) => {
    const [time, setTime] = useState(0);
    const [online, setOnline] = useState(true);
    const [admin, setAdmin] = useState(process.env.REACT_APP_ADMIN);
    const [game, setGame] = useState([]);
    const [gameName, setGameName] = useState("");
    const [error, setError] = useState("");
    const [mode, setMode] = useState("normal"); // normal, test
    const [updated, setUpdated] = useState(0);

    useMemo(() => {
        if (updateTimer) {
            clearInterval(updateTimer);
        }
        updateTimer = setInterval(() => {
           // calculateSpeedAndDistance();
        }, 2000);
    }, []);

    function startGPS() {
        if (navigator.geolocation) {
            if (watchid) {
                navigator.geolocation.clearWatch(watchid);
                watchid = 0;
            };
            watchid = navigator.geolocation.watchPosition(updatePosition.bind(this), handleError, { enableHighAccuracy: true, timeout: 10000, maximumAge: 5000 });

        } else {
            setError("Geolocation is not supported or is disabled");
            console.log("Geolocation is not supported by this browser.");
        }

        setupTestMode();
    }

    function updatePosition(position) {
        setError(null);
        //setPreviousLatitude(previousLatitude => +latitude);
        //setpreviousLongitude(previousLongitude => +longitude);
        previousLatitude = latitude;
        previousLongitude = longitude;
        //setPreviousTime(previousTime => currentTime);
        if (startLatitude === 0 && startLongitude === 0) {
            //setStartLatitude(startLatitude=> +position.coords.latitude);
            startLatitude = +position.coords.latitude;
            startLongitude = +position.coords.longitude;
            //setStartLongitude(startLongitude => +position.coords.longitude);
        }
        //setLatitude((latitude) => +position.coords.latitude);
        //setLongitude(longitude => +position.coords.longitude);
        latitude = +position.coords.latitude;
        longitude = +position.coords.longitude;
        
        calculateSpeedAndDistance();
        setUpdated(updated=>updated+1);
    }

    function calculateSpeedAndDistance() {

        currentTime = Date.now();
        distance = calculateDistance(startLatitude, startLongitude, latitude, longitude);        
        speed = calculateSpeed(previousLatitude, previousLongitude, previousTime, latitude, longitude, currentTime);

        previousLatitude = latitude;
        previousLongitude = longitude;
        previousTime = currentTime;
        
        
        console.log("distance: ", distance, "speed: ", speed);
    }

    function setTarget(lat, lon) {
        targetLatitude = lat;
        targetLongitude = lon;
    }

    function setStartToCurrent() {
        setError("stc");
        //setStartLatitude(latitude);
        //setStartLongitude(longitude);
        startLatitude = latitude;
        startLongitude = longitude;
        calculateSpeedAndDistance();
    }

    function stopGPS() {
        if (watchid) {
            navigator.geolocation.clearWatch(watchid);
            watchid = 0;
        }
    }

    function handleError(error) {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                setError("User denied the request for Geolocation.");
                break;
            case error.POSITION_UNAVAILABLE:
                setError("Location information is unavailable.");
                break;
            case error.TIMEOUT:
                setError("The request to get user location timed out.");
                break;
            case error.UNKNOWN_ERROR:
                setError("An unknown error occurred.");
                break;
            default:
                setError("An unknown error occurred.");
                break;
        }
    }



    function isGPSEnabled() {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
            if (result.state === 'granted') {
                console.log('Geolocation is enabled');
            } else if (result.state === 'prompt') {
                console.log('Geolocation is not enabled');
            } else if (result.state === 'denied') {
                console.log('Geolocation is disabled');
            }})
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    function setupTestMode() {
        if (mode === "test") {
            console.log("setupTestMode");
            let i = 0;
            let testTimer = setInterval(() => {
                if (i < testPositions.length) {
                    //setLatitude(testPositions[i].lat);
                    //setLongitude(testPositions[i].lon);
                    updatePosition({coords: {latitude: testPositions[i].lat, longitude: testPositions[i].lon}});
                    
                    //latitude = testPositions[i].lat;
                    //longitude = testPositions[i].lon;
                    i++;
                } else {
                    i = 0;
                }
            }, 500);
        }
    }

    return (
        <GameContext.Provider value={{
            admin, setAdmin, time, setTime,
            online, setOnline, game, setGame, gameName, setGameName,
            error, setError, startGPS, stopGPS, latitude, longitude, distance, setStartToCurrent,
            targetLatitude, targetLongitude, setTarget,
            speed, updated, games

        }}>
            <OnlineStatus></OnlineStatus>
            {children}
        </GameContext.Provider>
    );
};

export { GameContext, GameContextProvider };