import React, { useContext } from 'react';
import NavButton from '../components/NavButton';
import { GameContext } from '../Context';
import GameCard from '../components/gamecard/GameCard';

const Games = (props) => {

    const gameContext = useContext(GameContext);

    function gotoGame(gameName) {
        gameContext.setGameName(gameName);
        props.setPage("start");
    }

    return (
        <div>
            <header className="App-header">
                <div className="imgplaceholder"><img src="/goplay4_cropped.jpg" className="gameimage"></img></div>
            </header>
            <h1>Upcoming Geo Games</h1>
            <div className='vcontainer'>
                <div>Cape Town 2024/2025</div>                
                {gameContext.games.map((game, index) => <GameCard index={index} game={game} gotoGame={gotoGame}></GameCard>)}
            </div>

        </div>
    );
}

export default Games;