import React from 'react';
import './quiz.css';

const GameClue = (props) => {
    const [showHint, setShowHint] = React.useState(false);

    function doit() {
        if (!showHint) {
            props.penalty();
        }
        setShowHint(true);
    }

    return (
        <div className='quiz'>
            {props.clue && <div className='clue'>{props.clue}</div>}
            <div className="hint">
                <div className='button' onClick={doit}>Hint (-1 Point)</div>
                {showHint && <p>{props.hint}</p>}
            </div>
        </div>
    );
}

export default GameClue;