import React, { useContext } from 'react';
import NavButton from '../components/NavButton';
import { GameContext } from '../Context';

const Home = (props) => {
    const gameContext = useContext(GameContext);

    function showAdmin() {
        if (gameContext.admin) {
            return <NavButton setPage={() => props.setPage("admin")}>Admin</NavButton>
        }
    }

    return (
        <div>
            <header className="App-header">
                <h3>Wonderland Games Presents</h3>
                <h1>GoPlay™ Outside BETA</h1>
                <p>Outdoor adventures for individuals, teams, friends, and families</p>
            </header>
            <div>
                <NavButton setPage={() => props.setPage("games")}>View Games</NavButton>
                <NavButton setPage={() => props.setPage("prizes")} >Prizes</NavButton>
                {showAdmin()}
            </div>
            <p>Best Experienced on Mobile</p>

            <a href="/terms.html">Terms of Service</a>
            <div>-</div>
            <a href="/privacy.html">Privacy Policy</a>

        </div>
    );
};

export default Home;