import React, { useContext, useEffect, useReducer, useState } from 'react';
import CheckList from '../components/CheckList';
import GameButton from '../components/GameButton';
import Location from '../components/Location/Location';
import JigSaw from '../components/jigsaw/JigSaw';
import Match2 from '../components/match2/match2';
import { GameContext } from '../Context';
import ScrollArea from '../components/scrollarea/ScrollArea';
import LockPick from '../components/lockpick/LockPick';
import Quiz from '../components/quiz/Quiz';
import Cypher from '../components/cypher/cypher';
import { getUser } from '../lib/user';

const GeoGame = (props) => {

    const gameContext = useContext(GameContext);

    const [chapter, setChapter] = useState(0);
    const [mode, setMode] = useState('normal');
    const [score, setScore] = useState(0);
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [status, setStatus] = useState('loading...');
    const [time, setTime] = useState(0);

    useEffect(() => {

        // fetch game
        setStatus('loading...');
        fetch("/" + props.game + '/game.json')
            .then(response => response.json())
            .then(data => {
                gameContext.setGame(data);
                //game = data;
                forceUpdate();
            })
            .then(() => {


            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    useEffect(() => {

    }, gameContext.error);


    useEffect(() => {
        props.setStage && props.setStage(chapter);
        checkTime();
        captureMetrics();
    }, [chapter]);

    useEffect(() => {
        setStatus("");
        let savedgame = localStorage.getItem(gameContext.gameName + "_game");
        if (savedgame) {
            // merge in the completed and score from the saved game
            // todo make smarter, e.g. a new section added
            savedgame = JSON.parse(savedgame);
            for (let i = 0; i < savedgame.length; i++) {
                if (gameContext.game[i]) {
                    gameContext.game[i].complete = savedgame[i].complete;
                    gameContext.game[i].score = savedgame[i].score;
                    gameContext.game[i].time = savedgame[i].time || 0;
                }
            }
            //game = JSON.parse(savedgame);
        }
        let savedchapter = getLocalStorage('chapter');
        if (savedchapter) {
            setChapter(+savedchapter + 1);
        } else {
            setChapter(0);
        }

    }, [gameContext.game]);

    function captureMetrics() {
        const username = getUser()?.name || "Guest";
        window.UserMetrics && window.UserMetrics.metric("chapter", chapter, {
            chapter, score, status, time, username, game: gameContext.gameName,
            latitude: gameContext.latitude, longitude: gameContext.longitude, 
            targetLatitude: gameContext.targetLatitude, targetLongitude: gameContext.targetLongitude
        });
    };

    function getLocalStorage(tag) {
        return localStorage.getItem(gameContext.gameName + "_" + tag);
    }

    function setLocalStorage(tag, value) {
        localStorage.setItem(gameContext.gameName + "_" + tag, value);
    }

    function checkTime() {
        if (chapter > 0) {
            const stime = getLocalStorage('stime');
            if (!stime) {
                localStorage.setItem('stime', Date.now());
            }
        }

        if (chapter >= gameContext.game.length) {
            const etime = getLocalStorage('etime');
            setLocalStorage('etime', Date.now());            
            return;
        }
    }

    function gotoChapter(newchapter) {        
        if (newchapter >= gameContext.game.length) {            
            captureMetrics();
            props.setPage("leaderboard");
            return;
        }
        gameContext.game[chapter].complete = true;
        gameContext.game[chapter].score = 2;
        saveGame();
        setChapter(newchapter);
        forceUpdate();
    }

    /**
     * save game to local storage
     */
    function saveGame() {
        console.log(gameContext.game);
        setLocalStorage('chapter', chapter);
        setLocalStorage('game', JSON.stringify(gameContext.game));
    }

    function calculateScore() {
        let total = 0;
        for (let i = 0; i < gameContext.game.length; i++) {

            if (typeof gameContext.game[i].score !== 'undefined') {
                total += gameContext.game[i].score || 0;
            }
        }
        return <div className='score'>
            <p>Score: {total}</p>
        </div>;
    }

    function showProgress() {
        return <div className='progress'>
            <p>Progress: {Math.floor((chapter + 1) / gameContext.game.length * 100)}%</p>
        </div>
    }

    function getChapter() {
        if (chapter >= gameContext.game.length) {
            return { stage: "Game Over", description: "You have completed the game. A hearty congratulations from GoPlay! <br>Stay tuned for more games, high scores, and photos. <br><br>Keep an eye on our social media pages about those prizes! <br><br> We welcome feedback both positive and negative, so please let us know about your game experience!" };
        }
        return gameContext.game[+chapter];
    }

    function getMaxCompletedChapter() {
        let max = 0;
        for (let i = 0; i < gameContext.game.length; i++) {
            if (gameContext.game[i].complete) {
                max = i;
            }
        }
        return max;
    }

    function onReachedTarget() {
        console.log("Reached target");
        completeChapter();
        saveGame();
        setChapter(chapter + 1);
    }


    function onAnswered() {
        console.log("Answered");
        completeChapter();
        saveGame();
        setChapter(chapter + 1);
    }

    function onComplete() {
        console.log("Game complete");
        completeChapter();
        saveGame();
        setChapter(chapter + 1);
    }

    function onSkip() {
        console.log("Skipped");
        gameContext.game[chapter].complete = true;
        gameContext.game[chapter].skipped = true;
        saveGame();
        setChapter(chapter + 1);
    }

    function completeChapter() {
        if (!gameContext.game[chapter].complete) {
            if (typeof gameContext.game[chapter].score === 'undefined') {
                gameContext.game[chapter].score = 0;
            }
            gameContext.game[chapter].score = gameContext.game[chapter].score + 2;
        }
        gameContext.game[chapter].complete = true;
        gameContext.game[chapter].skipped = false;
    }


    function penalty() {
        if (typeof gameContext.game[chapter].score === 'undefined') {
            gameContext.game[chapter].score = 0;
        }
        gameContext.game[chapter].score = gameContext.game[chapter].score - 1;
        setScore(score - 1);
        saveGame();
    }

    function renderGoto() {
        gameContext.setTarget(gameContext.game[chapter].latitude, gameContext.game[chapter].longitude);
        return (
            <div className="instructions">
                <div>{getChapter().instructions}</div>
                <Location onReachedTarget={onReachedTarget} onSkip={onSkip} label={getChapter().targetlabel} latitude={getChapter().latitude} longitude={getChapter().longitude} />
            </div>)
    }

    function renderQuiz() {
        return (
            <div>
                <Quiz onAnswered={onAnswered} onSkip={onSkip} showAnswer={getChapter().complete}
                    type="text" answers={getChapter().answers} hint={getChapter().hint} clue={getChapter().clue} penalty={penalty}
                    placeholder="Answer" id="answer" />
            </div>)
    }

    function renderJigsaw() {
        return (
            <div>
                <JigSaw onComplete={onComplete} initialImage={getChapter().jgraphic}></JigSaw>
                <GameButton to={onSkip} >Skip</GameButton>
            </div>
        )
    }

    function renderMatch2() {
        return (
            <div>
                <Match2 onComplete={onComplete} />
                <GameButton to={onSkip} >Skip</GameButton>
            </div>
        )
    }

    function renderLockPick() {
        return (
            <div className='game'>
                <LockPick onComplete={onComplete} key={new Date().getTime()} />
                <GameButton to={onComplete}>Skip</GameButton>
            </div>
        )
    }

    function renderCypher() {
        return (
            <div>
                <p>{getChapter().instructions}</p>
                <Cypher targetText={getChapter().target} onComplete={onComplete}></Cypher>
                <GameButton to={onComplete}>Skip</GameButton>
            </div>
        )
    }

    return (
        <header className="container">
            {gameContext.error}
            {status}
            <div className='chapter'>
                {chapter > 0 && <div className='score'>{calculateScore()} {showProgress()}</div>}
                <div className="gamechapter">
                    {chapter > 0 && <GameButton className="navButton" direction={true} to={setChapter} target={chapter - 1} text="" />}
                    {chapter === 0 && <GameButton className="navButton disabled" direction={true} to={setChapter} target={chapter - 1} text="" />}
                    <h1 className='chapterTitle'>{getChapter().stage}</h1>
                    {chapter <= getMaxCompletedChapter() && <GameButton className="navButton" direction={false} to={setChapter} target={chapter + 1} text="" />}
                    {chapter > getMaxCompletedChapter() && <GameButton className="navButton disabled" direction={false} to={() => { }} target={() => { }} text="" />}
                </div>
                {getChapter().graphic &&
                    <img src={getChapter().graphic} width="240" alt="spy" />
                }
                <div className="premise">{getChapter().premise}</div>
            </div>

            <div className="gamecontainer">
                {getChapter().description && <ScrollArea><div className="paragraph" dangerouslySetInnerHTML={{ __html: getChapter().description }}></div></ScrollArea>}

                {chapter === 0 && <CheckList setPage={props.setPage} />}
                {getChapter().type == 'text' && <GameButton to={gotoChapter} target={chapter + 1}>{getChapter().label || "Submit"}</GameButton>}
                {getChapter().type == 'goto' && renderGoto()}
                {getChapter().type == 'quiz' && renderQuiz()}
                {getChapter().type == 'jigsaw' && renderJigsaw()}
                {getChapter().type == 'match2' && renderMatch2()}
                {getChapter().type == 'lockpick' && renderLockPick()}
                {getChapter().type == 'cypher' && renderCypher()}
                {/* {gameContext.admin && <div>
                    <GameButton to={gotoChapter} target={chapter + 1} text={getChapter().label || "ADMINNext"} />
                </div> */}

            </div>
        </header>
    );
}

export default GeoGame;