import NavButton from "../NavButton";
import './gamecard.css';

const GameCard = ({ game, index, gotoGame }) => {

    function formatDate(dateString) {
        return new Date(dateString).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" })
    };

    return (
        <div key={index} className="gamecard">
            <div className="gametitle">
                <img src={game.image} className="gameimage"></img>
                {game.title}
            </div>
            {!game.available && <h2>Coming Soon</h2>}
            {game.available && <h2>{formatDate(game.date)}</h2>}
            <div className="gamedescription">{game.description}</div>
            <div className="gameattributes">
                {game.wheelchairfriendly && <img className="gameicon" src="/wheelchair.jpg" width="52"></img>}
                {game.family && <img className="gameicon" src="/familyfriendly.jpg" width="52"></img>}
                {game.petfriendly && <img className="gameicon" src="/dogfriendly.jpg" width="52"></img>}
            </div>
            <NavButton className="NavLink" setPage={() => gotoGame(game.name)}>Play {game.title}</NavButton>
        </div>
    )

}

export default GameCard;