function saveUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
}

function getUser() {
    const user = localStorage.getItem('user');
    if (!user) {
        return {loggedin:false};
    }
    const juser = JSON.parse(user);
    window.UserMetrics._clientid = juser.name || "guest";
    return juser;
}

function clearUser() {
    localStorage.removeItem('user');
}

function getCurrentGame() {
    return localStorage.getItem('gameName');
}

function getTickets() {
    return localStorage.getItem('tickets');
}

function hasTicketsForGame(game) {
    const tickets = getTickets();
    if (!tickets) {
        return false;
    }
    const jtickets = JSON.parse(tickets);
    return jtickets[game] > 0;
}

export { saveUser, getUser, clearUser, getCurrentGame, getTickets, hasTicketsForGame };