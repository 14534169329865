import React, { useContext, useEffect } from 'react';
import Location from '../components/Location/Location';
import GameButton from '../components/GameButton';
import { GameContext } from '../Context';


let price = 30;
let currencySymbol = "R";

const BuyTickets = ({setPage}) => {
    const [selectedGame, setSelectedGame] = React.useState(null);
    const [numberOfPlayers, setNumberOfPlayers] = React.useState(1);
    const gameContext = useContext(GameContext);

    function calculateTotalPrice() {
        let gameDetails = gameContext.games.find(game => game.name === selectedGame);
        if (!gameDetails) {
            return 0;
        }
        price = gameDetails.price;
        return price * numberOfPlayers;
    }

    function buyTickets() {
        let tickets = localStorage.getItem('tickets');
        if (!tickets) {
            tickets = {};
        } else {
            tickets = JSON.parse(tickets);
        }
        tickets[selectedGame] = numberOfPlayers;
        localStorage.setItem('tickets', JSON.stringify(tickets));
        setPage("start", "tickets");
    }

    return (
        <div className='tickets'>
            <h2>Buy Tickets</h2>
            <h3>Select a Game</h3>
            <div className="ticketcontainer">
                {gameContext.games.map((game, index) => {
                    return <div key={index}>
                        <input
                            type="radio"
                            id={index}
                            name="game"
                            value={game.name}
                            onChange={() => setSelectedGame(game.name)}
                        />
                        <label htmlFor={index}>{game.name} {currencySymbol}{game.price}</label>
                    </div>
                })}

                <p>Number of Players</p> <input id="numberofplayers" type="number" min="1" max="20" step="1" value={numberOfPlayers} onChange={(e) => setNumberOfPlayers(Number(e.target.value))}></input>
                <p>Total: {calculateTotalPrice()}</p>
                <div className="hcontainer">
                    <GameButton to={() => buyTickets()}>Buy Tickets</GameButton>
                </div>
            </div>
        </div>
    );
}

export default BuyTickets;