import React from 'react';
import Location from '../components/Location/Location';

const Test = (props) => {

    return (
        <div className='test'>
            <h4>GPS may take a minute to "warm up"</h4>
            <p>It is possible to play with GPS turned off but this will degrade the game experience</p>
            <Location latitude={ -33.9041358} longitude={18.4114505} mode="debug" onSkip={()=>props.setPage("back")}></Location>            
        </div>
    );
}

export default Test;