function calculateDistance(latitude, longitude, targetLatitude, targetLongitude) {
    const R = 6371e3; // metres
    const φ1 = latitude * Math.PI / 180; // φ, λ in radians
    const φ2 = targetLatitude * Math.PI / 180;
    const Δφ = (targetLatitude - latitude) * Math.PI / 180;
    const Δλ = (targetLongitude - longitude) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // in metres
    return Math.round(distance);
}

function calculateHeading(latitude, longitude, targetlatitude, targetlongitude) {
    const φ1 = latitude * Math.PI / 180; // φ, λ in radians
    const φ2 = targetlatitude * Math.PI / 180;
    const Δλ = (targetlongitude - longitude) * Math.PI / 180;

    const y = Math.sin(Δλ) * Math.cos(φ2);
    const x = Math.cos(φ1) * Math.sin(φ2) -
        Math.sin(φ1) * Math.cos(φ2) * Math.cos(Δλ);
    const θ = Math.atan2(y, x);
    const heading = (θ * 180 / Math.PI + 360) % 360; // in degrees

    return Math.round(heading);
}

function calculateSpeed(previousLatitude, previousLongitude, previoustime, latitude, longitude, time) {
    const distance = calculateDistance(previousLatitude, previousLongitude, latitude, longitude);
    const duration = time - previoustime;
    const speed = distance / duration;
    return Math.round(speed*100)/100;
}


module.exports = {calculateDistance, calculateHeading, calculateSpeed};