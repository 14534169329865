import React from 'react';
import { MakeRequest } from '../lib/network';
import { saveUser } from '../lib/user';
import NavButton from '../components/NavButton';
import GameButton from '../components/GameButton';

const Login = (props) => {
    const [message, setMessage] = React.useState("");

    function DoLogin(event) {
        //event.preventDefault();
        console.log('Login');
        const email = document.getElementById('email').value.toLowerCase();

        const password = document.getElementById('password').value;
        MakeRequest('login', {
            email: email,
            password: password
        }).catch((error) => {
            console.error('Error:', error);
            setMessage("Error");
        }).then((data) => {
            console.log('Success:', data);
            setMessage(data.message);

            if (data.success) {
                saveUser(data.user);
                window.location.href = '/';
            }
        });
    }

    return (
        <div className='container'>
            <h2>Login</h2>
            <form>
                <div>{message}</div>
                <div className='formgroup'>
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" name="email" required />
                </div>
                <div class='formgroup'>
                    <label htmlFor="password">Password</label>
                    <input type="password" id="password" name="password" required />
                </div>
                <GameButton type="submit" to={DoLogin}>Login</GameButton>
            </form>
            <div>
                <br></br>
                <h3>Register</h3>
                <NavButton setPage={() => props.setPage("register")}>Register</NavButton>
            </div>
        </div>
    );
};

export default Login;