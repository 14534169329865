import React, { useEffect, useMemo, useReducer } from 'react';
import L from 'leaflet';
import GameButton from '../GameButton';
import { GameContext } from '../../Context';
import { greenIcon, playerIcon, homeIcon, targetIcon } from './Icons';
import { calculateDistance, calculateHeading } from './LocationFunctions';
import './location.css';

let zoomlevel = 17;
let themap = null;
let locationMarker = null;
let targetMarker = null;
let targetCircle = null;
let exittimer = null;


// error.code can be:
//   0: unknown error
//   1: permission denied
//   2: position unavailable (error response from location provider)
//   3: timed out
const Location = (props) => {

    const gameContext = React.useContext(GameContext);
    const [heading, setHeading] = React.useState(0);
    const [altitude, setAltitude] = React.useState(0);
    const [maplatitide, setMapLatitude] = React.useState(0);
    const [maplongitude, setMapLongitude] = React.useState(0);
    const [updating, setUpdating] = React.useState(false);
    const [, forceUpdate] = useReducer(x => x + 1, 0);


    useEffect(() => {
        console.log("useEffect");
        setupMap();
        gotoLocation();
        setTarget();
        gameContext.startGPS();

        return () => {
            if (exittimer) {
                clearInterval(exittimer);
            }
        };
        //forceUpdate();
    }, []);

    useEffect(() => {
        console.log("location");
        gotoLocation();
        setMyLocation();
        checkIfNear();
    }, [gameContext]);

    useEffect(() => {
        setTarget();
        console.log("target", gameContext.targetLatitude, gameContext.targetLongitude);
    }, [gameContext.targetLatitude, gameContext.targetLongitude]);

    function checkIfNear() {
        if (calculateDistance(gameContext.latitude, gameContext.longitude, gameContext.targetLatitude, gameContext.targetLongitude) < 30) {
            console.log("You are near the target");
            //alert("You are near the target");
            exittimer = setTimeout(() => {
                if (props.onReachedTarget) {
                    props.onReachedTarget();
                }
            }, 1000);

        }
    }

    function gotoLocation() {
        // truncate lat lon to 0.0001 to counter drift and rapid changes
        let lat = gameContext.targetLatitude;
        let lon = gameContext.targetLongitude;
        if (themap && gameContext.latitude !== 0 && gameContext.longitude !== 0) {
            lat = Math.round(gameContext.latitude * 1000) / 1000;
            lon = Math.round(gameContext.longitude * 1000) / 1000;
        } else {
            lat = Math.round(gameContext.targetLatitude * 1000) / 1000;
            lon = Math.round(gameContext.targetLongitude * 1000) / 1000;
        }

        themap.setView([lat, lon], zoomlevel, { animate: false });
    }

    function setupMap() {
        if (themap) {
            themap.off();
            themap.remove();
        }

        themap = L.map('map');

        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(themap);
        setTarget();

        themap.on('click', function (e) {
            //alert("Lat, Lon : " + e.latlng.lat + ", " + e.latlng.lng);            
            setMapLatitude(e.latlng.lat);
            setMapLongitude(e.latlng.lng);
            window.UserMetrics && window.UserMetrics.metric("manuallocation", 1, { latitude: e.latlng.lat, longitude: e.latlng.lng });
        });

        // add north arrow in the top right
        L.Control.North = L.Control.extend(
            {
                onAdd: function (themap) {
                    let div = L.DomUtil.create("div", "info legend");
                    div.innerHTML = "⬆N";
                    return div;
                }
            }
        )

        L.control.north = function (opts) {
            return new L.Control.North(opts);
        }

        L.control.north({ position: "topright" }).addTo(themap);

        setupMarkers();
    }

    /**
     * Adds markers for completed stages
     */
    function setupMarkers() {
        if (locationMarker) {
            themap.removeLayer(locationMarker);
        }
        locationMarker = L.marker([gameContext.latitude, gameContext.longitude], { icon: playerIcon }).addTo(themap);
        //locationMarker = L.marker([gameContext.latitude, gameContext.longitude]).addTo(themap);

        gameContext.game.map(stage => {
            if (stage.complete && stage.type === "goto") {
                // L.marker([stage.latitude, stage.longitude]).addTo(themap).bindPopup(stage.label);
                let ticon = { icon: greenIcon };
                console.log(stage.icon);
                switch (stage.icon) {
                    case "home": ticon = { icon: homeIcon }; break;
                    case "player": ticon = { icon: playerIcon }; break;
                    default: ticon = { icon: greenIcon }; break;
                }

                L.marker([stage.latitude, stage.longitude], ticon).addTo(themap);

            }
        });
    }

    function setMyLocation() {
        if (gameContext.latitude === 0 || gameContext.longitude === 0) {
            return;
        }

        if (locationMarker) {
            // move the marker
            locationMarker.setLatLng([gameContext.latitude, gameContext.longitude]);
        }

        // move the map so that both markers are visible
        try {
            if (targetMarker && locationMarker) {
                const group = new L.featureGroup([targetMarker, locationMarker]);
                themap.fitBounds(group.getBounds().pad(0.15), { animate: true, maxZoom: zoomlevel });
            }
        } catch (e) {
            console.log(e);
        }
    }

    function setTarget() {
        if (themap) {

            if (gameContext.targetLatitude === 0 || gameContext.targetLongitude === 0) {
                return;
            }

            if (targetMarker) {
                themap.removeLayer(targetMarker);
                themap.removeLayer(targetCircle);
            }

            targetMarker = L.marker([gameContext.targetLatitude, gameContext.targetLongitude], { icon: targetIcon }).addTo(themap);

            targetCircle = L.circle([gameContext.targetLatitude, gameContext.targetLongitude], {
                color: 'red',
                fillColor: '#f03',
                fillOpacity: 0.15,
                radius: 30
            }).addTo(themap);
        }
    }

    function getHeadingArrow() {
        return (
            <svg width="32" height="32" viewBox="0 0 100 100" style={{ transform: "rotate(" + calculateHeading(gameContext.latitude, gameContext.longitude, gameContext.targetLatitude, gameContext.targetLongitude) + "deg)" }}>
                <path d="M 50 0 L 100 100 L 50 80 L 0 100 Z" fill="red"></path>
            </svg>
        );
    }

    function round(value) {
        return Number(Math.round(value * 1000) / 1000);
    }

    function onSkip() {
        props.onSkip();
    }

    return (
        <header className="map">
            <div className='left'>
                <div id="map"></div>
                {maplatitide !== 0 && maplongitude !== 0 && <div>{Math.round(maplatitide * 10000) / 10000} {Math.round(maplongitude * 10000) / 10000}</div>}
            </div>
            <div className='hcontainer'>
                <div className='locationinfo'>
                    {props.mode === "debug" && <div>Heading {heading}</div>}
                    {props.mode === "debug" && <div>Altitude {altitude}</div>}
                    <div className='info'>
                        <p>You: {round(gameContext.latitude)}, {round(gameContext.longitude)}</p>
                    </div>
                    <div className='info'>
                        {props.label && <p>{props.label}</p>}
                        <p>Goto: {round(gameContext.targetLatitude)}, {round(gameContext.targetLongitude)}</p>
                    </div>
                    <div className='info'>
                        <p>Distance: {gameContext.distance}m </p>
                        <p>{updating && <div>Updating...</div>}</p>
                        {/* <GameButton to={resetMap} target={latitude} text="Reset" /> */}
                    </div>
                </div>
                <div className='locationarrow'>
                    {getHeadingArrow()}
                </div>
                {/* <div>{error}</div> */}
            </div>
            {<GameButton to={onSkip} target={gameContext.latitude} >Skip</GameButton>}

        </header>

    );
}

export default Location;