import './App.css';

import Help from './routes/Help';
import Games from './routes/Games';
import Menu from './components/Menu';
import Home from './routes/Home';
import Test from './routes/Test';
import Login from './routes/Login';
import AdminHelp from './routes/Admin';
import Register from './routes/Register';
import GeoGame from './games/GeoGame';
import Prizes from './routes/Prizes';
import { useEffect, useState } from 'react';
import Reset from './routes/Reset';
import Logout from './routes/Logout';
import Profile from './routes/Profile';
import Leaderboard from './routes/Leaderboard';
import Start from './routes/Start';
import Summary from './routes/Summary';
import BookWalk from './components/BookWalk.js/BookWalk';
import BuyTickets from './routes/BuyTickets';

function App() {

  const [page, setAppPage] = useState('home');
  const [returnPage, setReturnPage] = useState('home');
  const [stage, setStage] = useState(0);

  useEffect(() => {
    const page = localStorage.getItem('page');
    if (page) {
      setAppPage(page);
    }
  }, []);

  useEffect(() => {
    if (window.UserMetrics) {
      window.UserMetrics.setHost(process.env.REACT_APP_USERMETRICS_HOSTNAME);
      window.UserMetrics._funnel = { "funnelid": page, "page": stage };
    }
  }, [page, stage]);

  function setPage(page, backPage) {
    console.log(page);    
    if (page === "back") {
      setAppPage(returnPage);
    } else {
      setReturnPage(backPage);
      setAppPage(page);
    }
    localStorage.setItem('page', page);
  }

  return (
    <div className="App">
      <Menu setPage={setPage}></Menu>
      {page === 'admin' && <AdminHelp setPage={setPage}></AdminHelp>}
      {page === 'home' && <Home setPage={setPage}></Home>}
      {page === 'help' && <Help setPage={setPage}></Help>}
      {page === 'games' && <Games setPage={setPage}></Games>}
      {page === 'adminhelp' && <AdminHelp setPage={setPage}></AdminHelp>}
      {page === 'login' && <Login setPage={setPage}></Login>}
      {page === 'logout' && <Logout setPage={setPage}></Logout>}
      {page === 'register' && <Register setPage={setPage}></Register>}
      {page === 'test' && <Test setPage={setPage}></Test>}
      {/* {page === 'logout' && <Logout setPage={setPage}></Logout>} */}
      {page === 'prizes' && <Prizes setPage={setPage}></Prizes>}            
      {page === 'reset' && <Reset setPage={setPage}></Reset>}
      {page === 'profile' && <Profile setPage={setPage}></Profile>}
      {page === 'start' && <Start setPage={setPage}></Start>}
      {page === 'summary' && <Summary setPage={setPage}></Summary>}
      {page === 'leaderboard' && <Leaderboard setPage={setPage}></Leaderboard>}
      {page === 'tickets' && <BuyTickets setPage={setPage}></BuyTickets>}

      {page === 'tester' && <GeoGame game="tester" setPage={setPage} setStage={setStage}></GeoGame>}      
      {page === 'ispy' && <GeoGame game="ispy" setPage={setPage} setStage={setStage}></GeoGame>}
      {page === 'maple' && <BookWalk book="maple" setPage={setPage}></BookWalk>}
    </div>
  );
}

export default App;
