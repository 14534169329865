import React, { useContext, useEffect, useState } from 'react';
import { MakeRequest, gphost } from '../lib/network';
import NavButton from '../components/NavButton';
import JigSaw from '../components/jigsaw/JigSaw';
import Match2 from '../components/match2/match2';
import LockPick from '../components/lockpick/LockPick';
import { GameContext } from '../Context';
import GameButton from '../components/GameButton';
import AudioPlayer from '../components/audioplayer/audioplayer';
import Cypher from '../components/cypher/cypher';

const AdminHelp = () => {

    const [dberror, setDberror] = useState("Checking...");
    const [game, setGame] = useState("");
    const [score, setScore] = useState(0);
    const gameContext = useContext(GameContext);    
    const [error, setError] = useState("");

    useEffect(() => {
        CheckDatabase();
    }, []);

    useEffect(() => {
        setError(gameContext.error);
    }, [gameContext.error]);


    async function CheckDatabase() {
        await MakeRequest('checkdatabase', {})
            .then((data) => {
                console.log('Success:', data);
                setDberror(data.message);
                return <div>OK</div>
            })
            .catch((error) => {
                console.error('Error:', error);
                setDberror("Error");
                return <div>Error</div>
            });
    }

    function onComplete(score) {
        console.log("complete");
        setScore(score);
        setTimeout(() => {
            setGame("");
        }, 1000);
    }

    function enableGPS() {
        gameContext.startGPS();
    }

    return (
        <div>
            <header className="App-header">
                <h3>Admin Help</h3>
                <small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small>
                <p>Host: {process.env.REACT_APP_HOSTNAME}</p>
                <p>{gphost}</p>
                <p>Database: {dberror}</p>
                <p>Score: {score}</p>
                <p>Error:{error}</p>
                <p>{gameContext.latitude},{gameContext.longitude}</p>
                <div className='vcontainer'>
                    <GameButton to={enableGPS}>Enable GPS</GameButton>
                    <NavButton setPage={() => setGame("jigsaw")}>Jigsaw</NavButton>
                    <NavButton setPage={() => setGame("match2")}>Match2</NavButton>
                    <NavButton setPage={() => setGame("lockpick")}>Lockpick</NavButton>
                    <NavButton setPage={() => setGame("audioplayer")}>Audio Player</NavButton>
                    <NavButton setPage={() => setGame("cypher")}>Cypher</NavButton>                    
                </div>
                {game === "jigsaw" && <JigSaw initialImage="/ispy/crypticn.jpg" onComplete={onComplete}></JigSaw>}
                {game === "match2" && <Match2 onComplete={onComplete}></Match2>}
                {game === "lockpick" && <LockPick onComplete={onComplete}></LockPick>}
                {game === "audioplayer" && <AudioPlayer folder="maple" onComplete={onComplete}></AudioPlayer>}
                {game === "cypher" && <Cypher targetText="ocean" onComplete={onComplete}></Cypher>}
            </header>


            <p></p>
        </div>
    );
}

export default AdminHelp;

